<template>
	<div>
		<h1>Mitch Insurance Portal</h1>

		<div class="row">
			<div class="col-12 col-md-6 col-lg-3 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>Mitch API Configuration</b></div>
					<div class="card-body text-center">
						<router-link to="/config"><i class="fa-regular fa-cogs fa-5x"></i></router-link>
					</div>
					<div class="card-footer text-center">Manage configuration for the Mitch Insurance API.</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-3 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>Mitch Data Export</b></div>
					<div class="card-body text-center">
						<a href="https://api.mitchinsurance.com/export" target="_blank"><i class="fa-regular fa-clipboard-list fa-5x"></i></a>
					</div>
					<div class="card-footer text-center">Export M1 and auto quotes.</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-3 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>Quote Forms</b></div>
					<div class="card-body text-center">
						<router-link to="/quotes"><i class="fa-regular fa-clipboard-list fa-5x"></i></router-link>
					</div>
					<div class="card-footer text-center">Quick links to quote forms.</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-3 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>FORTUS Carriers</b></div>
					<div class="card-body text-center">
						<router-link to="/carriers"><i class="fa-regular fa-buildings fa-5x"></i></router-link>
					</div>
					<div class="card-footer text-center">Manage insurance carriers used by FORTUS.</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-3 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>Facility M1 Data</b></div>
					<div class="card-body text-center">
						<router-link to="/facility_m1"><i class="fa-regular fa-motorcycle fa-5x"></i></router-link>
					</div>
					<div class="card-footer text-center">Upload new Facility M1 motorcycle rates.</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-3 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>Users</b></div>
					<div class="card-body text-center">
						<router-link to="/users"><i class="fa-regular fa-users fa-5x"></i></router-link>
					</div>
					<div class="card-footer text-center">Manage users who can access this portal.</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-3 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>My Account</b></div>
					<div class="card-body text-center">
						<router-link :to="'/user/' + user.user_id"><i class="fa-regular fa-user fa-5x"></i></router-link>
					</div>
					<div class="card-footer text-center">Manage your own access to this portal.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'Dashboard',
		computed: {
			...mapGetters([
				"user"
			])
		}
	}
</script>
